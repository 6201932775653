<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo" v-translate>{{  $gettext('Senha para emissão de caixa') }}</h1>

      <div class="box-form"  style="max-width: 600px;">
        <v-card flat>
          <v-form>
            <v-card-text class="modal-cadastro-form">
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput">
                  <autoCompleteEntities :label="`Selecione um ${getEntityType(3)}`" :entityProps.sync="entity" :typesEntities="['3']" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-col class="d-flex justify-center align-center" style="min-height: 200px; width: 100%;" v-if="loadingModal">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>

              <v-card-text v-else>
                <date style="text-align: right;" />
                <v-list
                  subheader
                  three-line
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Senha para Pre-Caixa:</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 25px;">
                        {{ password }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card> 
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required  } from 'vuelidate/lib/validators'

// import validacao from './validacao'

/**
 * MIXIN
 */
// import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'senhaPreCaixa'

export default {
  name: 'Cancelamento',
  components: {
    // BrToolBar: () => import('./components/toolbar')
    autoCompleteEntities: () => import('../../components/autoCompleteEntities'),
    date: () => import('@/views/components/date')
  },
  mixins: [validationMixin],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Nr. Jogo', value: 'nrJogo' },
      { align: 'start', class: 'table-header', text: 'Venda', value: 'entityName' },
      { align: 'start', class: 'table-header', text: 'Código', value: 'transmissionCod' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'dt. Registro', value: 'created' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],

    loading: false,
    show: false,
    loadingModal: false,
    entity: null,
    password: null
  }),

  mounted () {
    // this.obterItens({ _filter: { 'entityTypeId_In': '1, 2, 3, 4, 5' } })
  },

  watch: {
    entity (val) {
      this.show = true
      this.loadingModal = true     
      this.getItens({ entityId: val.value })
        .then(e => this.password = e.data.data.password)
        .finally(() => this.loadingModal = false)
    }
  },

  computed: {
    ...mapGetters(namespaceStore, ['listaItens']),
    ...mapGetters('entities', ['listaItensSelect', 'listEntityTypes']),

    // ...validacao
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens']),
    getEntityType (id) {
      let str = this.listEntityTypes.filter(item => item.id === id)[0].text
      str = str.toLowerCase()
      // str = str.charAt(0).toUpperCase() + str.substr(1)

      return str
   }
  },

  validations () {
    return  {
      item: {
        entityId: { required },
        // nrJogo: { required },
        codBilhete: { required }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .box-form {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .3);
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }

  
.abas {
  margin-left: 10px;
  padding-top: 10px;
  .v-tab {
    font-size: 14px;
  }

  .abas-item {
    background-color: transparent;
    border: 1px solid $colorPrimary;
    color: $colorPrimary !important;
    padding-top: 2px;
    
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:before {
      display: none;
    }

    &.v-tab--active {
      background-color: $colorPrimary;
      color: #FFF !important;
    }

  }
  .v-tabs .v-tabs-slider-wrapper {
    display: none;
  }
}
</style>>
